import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Phone from 'img/phone.inline.svg'
import Textballoon from 'img/textballoon.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BannerCTAProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerCTA = styled.section``

const Banner = styled.div<{ background: string }>`
  padding: 40px 50px;
  border-radius: 10px 10px 0 0;
  position: relative;

  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}

  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      background-color: ${theme.color.primary};

      & h1,
      h2,
      h3,
      p,
      a {
        color: ${({ theme }) => theme.color.light};
      }

      & h1,
      h2,
      h3 {
        font-size: 35px;
      }

      & a {
        font-weight: ${({ theme }) => theme.font.weight.light};

        &:hover {
          color: ${({ theme }) => theme.color.secondary};
          text-decoration: underline;
        }
      }
    `}

  @media (max-width: 575px) {
    padding: 25px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  max-width: 440px;
  position: absolute !important;
  bottom: 0;
  right: -80px;
  pointer-events: none;

  @media (max-width: 1400px) {
    right: -150px;
  }

  @media (max-width: 767px) {
    max-width: 280px;
    right: -50px;
  }

  @media (max-width: 575px) {
    max-width: 200px;
    right: -30px;
  }

  @media (max-width: 320px) {
    display: none;
  }
`

const Links = styled.div`
  & svg {
    width: 40px;
    margin-right: 10px;
  }
`

const BannerCTA: React.FC<BannerCTAProps> = ({ fields }) => (
  <StyledBannerCTA>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-7">
          <Banner background={fields.backgroundcolor || 'primary'}>
            <StyledPlaatjie image={fields.image} alt="" />
            <ParseContent content={fields.description || ''} />
            <Links className="px-sm-3 pt-4">
              <div className="py-2">
                <a href={fields.phone?.url || '/'}>
                  <Phone />
                  {fields.phone?.title}
                </a>
              </div>
              <div className="py-2">
                <a href={fields.question?.url || '/'}>
                  <Textballoon />
                  {fields.question?.title}
                </a>
              </div>
            </Links>
          </Banner>
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  </StyledBannerCTA>
)

export default BannerCTA
